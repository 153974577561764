
let path = location.pathname
if (path == "/case/") {
  'use strict';

  if (!Array.from) {
    Array.from = (function () {
      var toStr = Object.prototype.toString;
      var isCallable = function (fn) {
        return typeof fn === 'function' || toStr.call(fn) === '[object Function]';
      };
      var toInteger = function (value) {
        var number = Number(value);
        if (isNaN(number)) { return 0; }
        if (number === 0 || !isFinite(number)) { return number; }
        return (number > 0 ? 1 : -1) * Math.floor(Math.abs(number));
      };
      var maxSafeInteger = Math.pow(2, 53) - 1;
      var toLength = function (value) {
        var len = toInteger(value);
        return Math.min(Math.max(len, 0), maxSafeInteger);
      };

      // The length property of the from method is 1.
      return function from(arrayLike/*, mapFn, thisArg */) {
        // 1. Let C be the this value.
        var C = this;

        // 2. Let items be ToObject(arrayLike).
        var items = Object(arrayLike);

        // 3. ReturnIfAbrupt(items).
        if (arrayLike == null) {
          throw new TypeError("Array.from requires an array-like object - not null or undefined");
        }

        // 4. If mapfn is undefined, then let mapping be false.
        var mapFn = arguments.length > 1 ? arguments[1] : void undefined;
        var T;
        if (typeof mapFn !== 'undefined') {
          // 5. else
          // 5. a If IsCallable(mapfn) is false, throw a TypeError exception.
          if (!isCallable(mapFn)) {
            throw new TypeError('Array.from: when provided, the second argument must be a function');
          }

          // 5. b. If thisArg was supplied, let T be thisArg; else let T be undefined.
          if (arguments.length > 2) {
            T = arguments[2];
          }
        }

        // 10. Let lenValue be Get(items, "length").
        // 11. Let len be ToLength(lenValue).
        var len = toLength(items.length);

        // 13. If IsConstructor(C) is true, then
        // 13. a. Let A be the result of calling the [[Construct]] internal method of C with an argument list containing the single item len.
        // 14. a. Else, Let A be ArrayCreate(len).
        var A = isCallable(C) ? Object(new C(len)) : new Array(len);

        // 16. Let k be 0.
        var k = 0;
        // 17. Repeat, while k < len… (also steps a - h)
        var kValue;
        while (k < len) {
          kValue = items[k];
          if (mapFn) {
            A[k] = typeof T === 'undefined' ? mapFn(kValue, k) : mapFn.call(T, kValue, k);
          } else {
            A[k] = kValue;
          }
          k += 1;
        }
        // 18. Let putStatus be Put(A, "length", len, true).
        A.length = len;
        // 20. Return A.
        return A;
      };
    }());
  }

  if (!Object.assign) {
    Object.defineProperty(Object, 'assign', {
      enumerable: false,
      configurable: true,
      writable: true,
      value: function (target) {
        'use strict';
        if (target === undefined || target === null) {
          throw new TypeError('Cannot convert first argument to object');
        }

        var to = Object(target);
        for (var i = 1; i < arguments.length; i++) {
          var nextSource = arguments[i];
          if (nextSource === undefined || nextSource === null) {
            continue;
          }
          nextSource = Object(nextSource);

          var keysArray = Object.keys(Object(nextSource));
          for (var nextIndex = 0, len = keysArray.length; nextIndex < len; nextIndex++) {
            var nextKey = keysArray[nextIndex];
            var desc = Object.getOwnPropertyDescriptor(nextSource, nextKey);
            if (desc !== undefined && desc.enumerable) {
              to[nextKey] = nextSource[nextKey];
            }
          }
        }
        return to;
      }
    });
  }

  if (!Array.prototype.includes) {
    Object.defineProperty(Array.prototype, 'includes', {
      value: function (searchElement, fromIndex) {

        if (this == null) {
          throw new TypeError('"this" is null or not defined');
        }

        var o = Object(this);
        var len = o.length >>> 0;

        if (len === 0) {
          return false;
        }

        var n = fromIndex | 0;
        var k = Math.max(n >= 0 ? n : len - Math.abs(n), 0);

        while (k < len) {
          if (o[k] === searchElement) {
            return true;
          }
          k++;
        }

        return false;
      }
    });
  }

  var Shuffle = window.Shuffle;

  var Sort = function (element) {
    this.items = Array.from(document.querySelectorAll('ul.sort_item li input'));
    this.styles = Array.from(document.querySelectorAll('ul.sort_length li button'));

    this.shuffle = new Shuffle(element, {
      easing: 'cubic-bezier(0.165, 0.840, 0.440, 1.000)', // easeOutQuart
    });

    this.filters = {
      items: [],
      styles: []
    };

    this._bindEventListeners();
  };

  /**
   * Bind event listeners for when the filters change.
   */
  Sort.prototype._bindEventListeners = function () {
    this._onItemChange = this._handleItemChange.bind(this);
    this._onStyleChange = this._handleStyleChange.bind(this);

    this.items.forEach(function (input) {
      input.addEventListener('change', this._onItemChange);
    }, this);

    this.styles.forEach(function (button) {
      button.addEventListener('click', this._onStyleChange);
    }, this);
  };

  /**
   * Get the values of each checked input.
   * @return {Array.<string>}
   */
  Sort.prototype._getCurrentitemFilters = function () {
    return this.items.filter(function (input) {
      return input.checked;
    }).map(function (input) {
      return input.value;
    });
  };

  /**
   * Get the values of each `active` button.
   * @return {Array.<string>}
   */
  Sort.prototype._getCurrentStyleFilters = function () {
    return this.styles.filter(function (button) {
      return button.classList.contains('active');
    }).map(function (button) {
      return button.getAttribute('data-value');
    });
  };

  /**
   * A item input check state changed, update the current filters and filte.r
   */
  Sort.prototype._handleItemChange = function (evt) {
    var input = evt.currentTarget;
    if (input.classList.contains('item_all')) {
      this.items.forEach(function (ipt) {
        ipt.checked = false;
      });
      input.checked = true;
    } else {
      if (input.checked == true) {
        this.items.forEach(function (ipt) {
          if (ipt.classList.contains('item_all')) {
            ipt.checked = false;
          }
        });
      } else {
        var check_count = $('ul.sort_item li input:checked').length;
        if (check_count == 0) {
          this.items.forEach(function (ipt) {
            if (ipt.classList.contains('item_all')) {
              ipt.checked = true;
            }
          });
        }
      }
    }
    this.filters.items = this._getCurrentitemFilters();
    if (this.filters.items.indexOf("all") >= 0) {
      this.filters.items.length = 0;
    }
    var sort_item = '';
    for (var i = 0; i < this.filters.items.length; i++) {
      sort_item += '#' + this.filters.items[i] + '&nbsp;&nbsp;';
    }
    document.getElementById('items').innerHTML = sort_item;
    this.filter();
  };

  /**
   * A style button was clicked. Update filters and display.
   * @param {Event} evt Click event object.
   */
  Sort.prototype._handleStyleChange = function (evt) {
    var button = evt.currentTarget;

    // Treat these buttons like radio buttons where only 1 can be selected.
    if (button.classList.contains('active')) {
      //     button.classList.remove('active');
    } else {
      this.styles.forEach(function (btn) {
        btn.classList.remove('active');
      });

      button.classList.add('active');
    }

    this.filters.styles = this._getCurrentStyleFilters();
    if (this.filters.styles.indexOf("all") >= 0) {
      this.filters.styles.length = 0;
    }
    var sort_style = '';
    for (var i = 0; i < this.filters.styles.length; i++) {
      sort_style += '#' + this.filters.styles[i] + '&nbsp;&nbsp;';
    }
    document.getElementById('styles').innerHTML = sort_style;
    this.filter();
  };

  /**
   * Filter shuffle based on the current state of filters.
   */
  Sort.prototype.filter = function () {
    if (this.hasActiveFilters()) {
      this.shuffle.filter(this.itemPassesFilters.bind(this));
    } else {
      this.shuffle.filter(Shuffle.ALL_ITEMS);
    }
  };

  /**
   * If any of the arrays in the `filters` property have a length of more than zero,
   * that means there is an active filter.
   * @return {boolean}
   */
  Sort.prototype.hasActiveFilters = function () {
    return Object.keys(this.filters).some(function (key) {
      return this.filters[key].length > 0;
    }, this);
  };

  /**
   * Determine whether an element passes the current filters.
   * @param {Element} element Element to test.
   * @return {boolean} Whether it satisfies all current filters.
   */
  Sort.prototype.itemPassesFilters = function (element) {
    var items = this.filters.items;
    var styles = this.filters.styles;
    var item = element.getAttribute('data-item');
    var style = element.getAttribute('data-length');

    // If there are active item filters and this item is not in that array.
    if (items.length > 0 && !items.includes(item)) {
      return false;
    }

    // If there are active style filters and this style is not in that array.
    if (styles.length > 0 && !styles.includes(style)) {
      return false;
    }
    return true;
  };
  document.addEventListener('DOMContentLoaded', function () {
    window.Sort = new Sort(document.querySelector('.snap_list'));
  });

}


